import React from 'react';
import { Link } from 'react-router';
import { Nav, Navbar, NavItem, NavDropdown, MenuItem, DropdownButton, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';

import { env } from '../config';
import '../css/sticky-footer.css';

export default class MainComponent extends React.Component {

	componentDidMount() {
		this.props.findAuthRole();
		this.props.findUserMessageUnreadCount();
	}

	changeCategory(category) {
		localStorage.setItem('fdlCategory', category);
		this.props.switchCategory(category);
	}

	render() {
		let envStyle = {
			color: 'red'
		};
		let pageEnv = env === '' ? '' : (<span style={envStyle}>({env})</span>);

		let { toSignOut } = this.props;

		let toChangePwd = () => {
			this.props.router.push('/changePwd');
		};
		let toUserItem = () => {
			this.props.router.push('/itemUser');
		};
		let toUserMessages = () => {
			this.props.router.push('/userMessages');
		};

		let emailVerified = false;
		let userStatus = -1;
		let userRole = -1;
		let userType = -1;
		let userName = '';
		let corpName = '消防局';
		if (this.props.role) {
			emailVerified = this.props.role.get('emailVerified');
			userStatus = this.props.role.get('status');
			userRole = this.props.role.get('role');
			userType = this.props.role.get('user_type');
			userName = this.props.role.get('user_name');
			if (this.props.role.get('corp_name')) {
				corpName = this.props.role.get('corp_name');
			}
		}

		let showSysAdmin = 'none';
		let showCorpAdmin = 'none';
		// let showCorp = 'none';
		let showSysAdminOrManu = 'none';
		let showCorpAdminOrManu = 'none';
		let showAll = 'none';

		if (emailVerified === true && userStatus === 1) {
			// 系統管理者
			if (userRole === 0) {
				showSysAdmin = 'block';
				showCorpAdmin = 'block';
				// showCorp = 'block';
				showSysAdminOrManu = 'block';
				showCorpAdminOrManu = 'block';
				showAll = 'block';
			}
			// 消防隊
			else if (userType === 1) {
				// 單位管理者 
				if (userRole === 1) {
					showSysAdmin = 'none';
					showCorpAdmin = 'block';
					// showCorp = 'block';
					showSysAdminOrManu = 'none';
					showCorpAdminOrManu = 'block';
					showAll = 'block';
				}
				// 一般使用者 
				else if (userRole === 2) {
					showSysAdmin = 'none';
					showCorpAdmin = 'none';
					// showCorp = 'block';
					showSysAdminOrManu = 'none';
					showCorpAdminOrManu = 'none';
					showAll = 'block';
				}
			}
			// 廠商 
			else if (userType === 2) {
				showSysAdmin = 'none';
				showCorpAdmin = 'none';
				// showCorp = 'none';
				showSysAdminOrManu = 'block';
				showCorpAdminOrManu = 'block';
				showAll = 'block';
			}
		}
		// 未通過 email 認證 or 帳號已停用
		else {
		}

		let menu = null;	// 頁面寬度 >=768使用
		let dropdownMenu = null; // 頁面寬度 <768使用
		let content = null;

		if (!this.props.category) {
			this.changeCategory(localStorage.getItem('fdlCategory'));
		}
		let collapseOneClass = "panel-collapse collapse";
		let collapseTwoClass = "panel-collapse collapse";
		let collapseThreeClass = "panel-collapse collapse";
		let collapseFourClass = "panel-collapse collapse";
		if (this.props.category === 'basic') {
			collapseOneClass = "panel-collapse collapse in";
			collapseTwoClass = "panel-collapse collapse";
			collapseThreeClass = "panel-collapse collapse";
			collapseFourClass = "panel-collapse collapse";
		} else if (this.props.category === 'logistics') {
			collapseOneClass = "panel-collapse collapse";
			collapseTwoClass = "panel-collapse collapse in";
			collapseThreeClass = "panel-collapse collapse";
			collapseFourClass = "panel-collapse collapse";
		} else if (this.props.category === 'emergency') {
			collapseOneClass = "panel-collapse collapse";
			collapseTwoClass = "panel-collapse collapse";
			collapseThreeClass = "panel-collapse collapse in";
			collapseFourClass = "panel-collapse collapse";
		} else if (this.props.category === 'imports') {
			collapseOneClass = "panel-collapse collapse";
			collapseTwoClass = "panel-collapse collapse";
			collapseThreeClass = "panel-collapse collapse";
			collapseFourClass = "panel-collapse collapse in";
		}

		if (this.props.role != null && emailVerified === true && userStatus === 1) {
			menu = (
				<div style={{ flex: "0 0 150px" }}>
					<div className="panel-group" id="accordion">
						<div className="panel panel-default" style={{ display: showSysAdminOrManu }}>
							<div className="panel-body" style={{ marginTop: -10, marginBottom: -10 }}>
								<ul className="nav nav-pills nav-stacked">
									<li style={{ display: showSysAdminOrManu }}><Link to="/choiceCorp">選擇單位</Link></li>
								</ul>
							</div>
						</div>
						<div className="panel panel-default" style={{ display: showCorpAdminOrManu }}>
							<div className="panel-heading">
								<h4 className="panel-title">
									<button style={{ outline: "none", border: "none" }} data-toggle="collapse" data-parent="#accordion"
										onClick={(event) => { this.changeCategory('basic') }}>
										基本資料管理
									</button>
								</h4>
							</div>
							<div id="collapseOne" className={collapseOneClass}>
								<div className="panel-body">
									<ul className="nav nav-pills nav-stacked">
										<li style={{ display: showSysAdmin }}><Link to="/corps">單位管理</Link></li>
										<li style={{ display: showCorpAdmin }}><Link to="/users">帳號管理</Link></li>
										<li style={{ display: showCorpAdmin }}><Link to="/announcementEdit">系統公告管理</Link></li>
										<li style={{ display: showCorpAdminOrManu }}><Link to="/squas">分隊管理</Link></li>
										<li style={{ display: showSysAdmin }}><Link to="/sendMessage">發送訊息</Link></li>
										<li style={{ display: showSysAdminOrManu }}><Link to="/messages">訊息管理</Link></li>
										<li style={{ display: showSysAdminOrManu }}><Link to="/warranty">保固年限設定</Link></li>
									</ul>
								</div>
							</div>
						</div>
						<div className="panel panel-default" style={{ display: showAll }}>
							<div className="panel-heading">
								<h4 className="panel-title">
									<button style={{ outline: "none", border: "none" }} data-toggle="collapse" data-parent="#accordion"
										onClick={(event) => { this.changeCategory('logistics') }}>
										裝備後勤管理
									</button>
								</h4>
							</div>
							<div id="collapseTwo" className={collapseTwoClass}>
								<div className="panel-body">
									<ul className="nav nav-pills nav-stacked">
										<li style={{ display: showAll }}><Link to="/itemReplacement">裝備汰換提醒</Link></li>
										<li style={{ display: showAll }}><Link to="/itemSearch">裝備查詢</Link></li>
										<li style={{ display: showCorpAdminOrManu }}><Link to="/gears">裝備類別管理</Link></li>
										<li style={{ display: showCorpAdminOrManu }}><Link to="/locations">保管地點管理</Link></li>
										<li style={{ display: showAll }}><Link to="/itemEdit/i">裝備新增</Link></li>
										<li style={{ display: showAll }}><Link to="/itemEditSearch">裝備修改</Link></li>
										<li style={{ display: showAll }}><Link to="/itemTransferSearch">裝備移轉</Link></li>
										<li style={{ display: showAll }}><Link to="/itemStatusChangeSearch">裝備狀態更改</Link></li>
										<li style={{ display: showAll }}><Link to="/mRecordEditSearch">維護記錄新增</Link></li>
										<li style={{ display: showAll }}><Link to="/mRecordSearch">維護記錄查詢</Link></li>
										<li style={{ display: showAll }}><Link to="/mRecordToBeClosed">維護待結案</Link></li>
									</ul>
								</div>
							</div>
						</div>
						<div className="panel panel-default" style={{ display: showCorpAdminOrManu }}>
							<div className="panel-heading">
								<h4 className="panel-title">
									<button style={{ outline: "none", border: "none" }} data-toggle="collapse" data-parent="#accordion"
										onClick={(event) => { this.changeCategory('imports') }}>
										裝備後勤匯入
									</button>
								</h4>
							</div>
							<div id="collapseTwo" className={collapseFourClass}>
								<div className="panel-body">
									<ul className="nav nav-pills nav-stacked">
										<li style={{ display: showCorpAdminOrManu }}><Link to="/locationUpload">保管地點匯入</Link></li>
										<li style={{ display: showCorpAdminOrManu }}><Link to="/itemUpload">裝備匯入</Link></li>
										<li style={{ display: showCorpAdminOrManu }}><Link to="/mRecordUpload">維護記錄匯入</Link></li>
									</ul>
								</div>
							</div>
						</div>
						<div className="panel panel-default" style={{ display: showSysAdmin }}>
							<div className="panel-heading">
								<h4 className="panel-title">
									<button style={{ outline: "none", border: "none" }} data-toggle="collapse" data-parent="#accordion"
										onClick={(event) => { this.changeCategory('emergency') }}>
										火場管制
									</button>
								</h4>
							</div>
							<div id="collapseThree" className={collapseThreeClass}>
								<div className="panel-body">
									<ul className="nav nav-pills nav-stacked">
										<li style={{ display: showSysAdmin }}><Link to="/emergencyEvent">事件建立</Link></li>
										<li style={{ display: showSysAdmin }}><Link to="/nfcChips">晶片設定查詢</Link></li>
										<li style={{ display: showSysAdmin }}><Link to="/emergencyRecord">火場進出管制</Link></li>
										<li style={{ display: showSysAdmin }}><Link to="/emergencyUserLocation">火場定位</Link></li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
			dropdownMenu = (
				<div style={{ flex: "0 0 100%" }}>
					<DropdownButton title="選單" id="bg-nested-dropdown">
						<MenuItem style={{ display: showSysAdminOrManu }} componentClass={Link} href="/choiceCorp" to="/choiceCorp">選擇單位</MenuItem>
						<MenuItem style={{ display: showSysAdminOrManu }} divider />
						<MenuItem style={{ display: showSysAdmin }} componentClass={Link} href="/corps" to="/corps">單位管理</MenuItem>
						<MenuItem style={{ display: showCorpAdmin }} componentClass={Link} href="/users" to="/users">帳號管理</MenuItem>
						<MenuItem style={{ display: showCorpAdmin }} componentClass={Link} href="/announcementEdit" to="/announcementEdit">系統公告管理</MenuItem>
						<MenuItem style={{ display: showCorpAdminOrManu }} componentClass={Link} href="/squas" to="/squas">分隊管理</MenuItem>
						<MenuItem style={{ display: showSysAdmin }} componentClass={Link} href="/sendMessage" to="/sendMessage">發送訊息</MenuItem>
						<MenuItem style={{ display: showSysAdminOrManu }} componentClass={Link} href="/messages" to="/messages">訊息管理</MenuItem>
						<MenuItem style={{ display: showSysAdminOrManu }} componentClass={Link} href="/warranty" to="/warranty">保固年限設定</MenuItem>
						<MenuItem style={{ display: showCorpAdminOrManu }} divider />
						<MenuItem style={{ display: showAll }} componentClass={Link} href="/itemReplacement" to="/itemReplacement">裝備汰換提醒</MenuItem>
						<MenuItem style={{ display: showAll }} componentClass={Link} href="/itemSearch" to="/itemSearch">裝備查詢</MenuItem>
						<MenuItem style={{ display: showCorpAdminOrManu }} componentClass={Link} href="/gears" to="/gears">裝備類別管理</MenuItem>
						<MenuItem style={{ display: showCorpAdminOrManu }} componentClass={Link} href="/locations" to="/locations">保管地點管理</MenuItem>
						<MenuItem style={{ display: showAll }} componentClass={Link} href="/itemEdit/i" to="/itemEdit/i">裝備新增</MenuItem>
						<MenuItem style={{ display: showAll }} componentClass={Link} href="/itemEditSearch" to="/itemEditSearch">裝備修改</MenuItem>
						<MenuItem style={{ display: showAll }} componentClass={Link} href="/itemTransferSearch" to="/itemTransferSearch">裝備移轉</MenuItem>
						<MenuItem style={{ display: showAll }} componentClass={Link} href="/itemStatusChangeSearch" to="/itemStatusChangeSearch">裝備狀態更改</MenuItem>
						<MenuItem style={{ display: showAll }} componentClass={Link} href="/mRecordEditSearch" to="/mRecordEditSearch">維護記錄新增</MenuItem>
						<MenuItem style={{ display: showAll }} componentClass={Link} href="/mRecordSearch" to="/mRecordSearch">維護記錄查詢</MenuItem>
						<MenuItem style={{ display: showAll }} componentClass={Link} href="/mRecordToBeClosed" to="/mRecordToBeClosed">維護待結案</MenuItem>
						<MenuItem style={{ display: showCorpAdminOrManu }} divider />
						<MenuItem style={{ display: showCorpAdminOrManu }} componentClass={Link} href="/locationUpload" to="/locationUpload">保管地點匯入</MenuItem>
						<MenuItem style={{ display: showCorpAdminOrManu }} componentClass={Link} href="/itemUpload" to="/itemUpload">裝備匯入</MenuItem>
						<MenuItem style={{ display: showCorpAdminOrManu }} componentClass={Link} href="/mRecordUpload" to="/mRecordUpload">維護記錄匯入</MenuItem>
						<MenuItem style={{ display: showSysAdmin }} divider />
						<MenuItem style={{ display: showSysAdmin }} componentClass={Link} href="/emergencyEvent" to="/emergencyEvent">事件建立</MenuItem>
						<MenuItem style={{ display: showSysAdmin }} componentClass={Link} href="/nfcChips" to="/nfcChips">晶片設定查詢</MenuItem>
						<MenuItem style={{ display: showSysAdmin }} componentClass={Link} href="/emergencyRecord" to="/emergencyRecord">火場進出管制</MenuItem>
						<MenuItem style={{ display: showSysAdmin }} componentClass={Link} href="/emergencyUserLocation" to="/emergencyUserLocation">火場定位</MenuItem>
					</DropdownButton>
				</div>
			);
			content = (
				<div style={{ flex: "1 0 80%" }}>
					<div className="container-fluid">
						{this.props.children}
					</div>
				</div>
			);
		} else {
			content = (
				<div style={{ flex: "0 0 100%" }}>
					<div className="container-fluid">
						{this.props.children}
					</div>
				</div>
			);
		}

		let messageBadge = null;
		let userMessageUnreadCount = this.props.userMessageUnreadCount
		if (userMessageUnreadCount && userMessageUnreadCount > 0) {
			messageBadge = (
				<span className="badge alert-danger" style={{ marginLeft: 5 }}>{userMessageUnreadCount}</span>
			)
		}

		let navDropdown = null;
		if (emailVerified === true && userStatus === 1) {
			if (userType === 2 && userRole === 2) {
				navDropdown = (
					<Nav pullRight>
						<NavDropdown eventKey={2} title={userName} id="basic-nav-dropdown">
							<NavItem eventKey={2.2} onSelect={toChangePwd}>更換密碼</NavItem>
							<NavItem eventKey={2.5} onSelect={toSignOut}>登出</NavItem>
						</NavDropdown>
					</Nav>
				);
			} else {
				navDropdown = (
					<Nav pullRight>
						<NavDropdown eventKey={2} title={userName} id="basic-nav-dropdown">
							<NavItem eventKey={2.2} onSelect={toChangePwd}>更換密碼</NavItem>
							<NavItem eventKey={2.3} onSelect={toUserItem}>個人裝備</NavItem>
							<NavItem eventKey={2.4} onSelect={toUserMessages}>個人訊息{messageBadge}</NavItem>
							<NavItem eventKey={2.5} onSelect={toSignOut}>登出</NavItem>
						</NavDropdown>
					</Nav>
				);
			}
		} else if (userRole) {
			navDropdown = (
				<Nav pullRight>
					<NavDropdown eventKey={2} title={userName} id="basic-nav-dropdown">
						<NavItem eventKey={2.3} onSelect={toSignOut}>登出</NavItem>
					</NavDropdown>
				</Nav>
			);
		}

		return (
			<div>
				<Navbar collapseOnSelect className="navbar-custom">
					<Navbar.Header>
						{pageEnv}
						<Navbar.Brand>
							<a href="/">
								<span className="font1"><img src="fd.png" alt="fd_logo" style={{ marginRight: 15 }} />{corpName}&nbsp;後勤管理系統</span>
							</a>
						</Navbar.Brand>
					</Navbar.Header>
					{navDropdown}
				</Navbar>
				<div style={{ marginBottom: 60 }}>
					<Row>
						<Col xs={12}>
							<div className="layoutMain">
								<div className="hiddenInWeb" >
									<div style={{ marginBottom: 15 }}>
										{dropdownMenu}
									</div>
								</div>
								<div className="hiddenInMobile">
									{menu}
								</div>
								{content}
							</div>
						</Col>
					</Row>
				</div>
				<footer className="footer">
					<div className="container">
						<p className="text-muted"> Copyright © 2020 系統開發: 固巧快實業有限公司 </p>
					</div>
				</footer>
			</div>
		)
	}
}